<template>
	<div class="enroll">
		<loginHeader :type='0'></loginHeader>
		
		<div class="account-zero">
			<h1 class="chektitle">请选择要创建账号的类型</h1>
		
			<el-radio-group v-model="radio" @change="onRadio">
				<el-radio :label="1">竞买个人账户</el-radio>
				<el-radio :label="2">竞买企业账户</el-radio>
			</el-radio-group>
			
			<div class="next-step J-step1-zero" @click="enrollNext">
				<em :class="radio !=''?'opacity':'' ">下一步</em>
			</div>
		</div>
	</div>
</template>

<script>
	import loginHeader from '@/components/login/loginHeader.vue'
	export default {
		data() {
			return {
				radio: ''
			};
		},
		components: {
			loginHeader,
			// pinless
		},
		created() {
			
		},
		methods: {
			// 单选框点击事件
			onRadio(radio){
				// console.log(radio)
			},
			// 注册下一步
			enrollNext(){
				let isForget = this.$route.query.isForget
				if(this.radio == ''){
					this.$message({
						message: '请选择账号的类型',
						type: 'error'
					})
					return
				}
				if(isForget){
					this.$router.push({
						path: '/forgetPassword',
						query: {
							type: this.radio
						},
					});
				}
				else{
					this.$router.push({
						path: '/register',
						query: {
							type: this.radio
						},
					});
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.enroll{
		background: #fff;
		.account-zero {
			display: block;
			width: 100%;
			margin: 45px auto 0;
			font-size: 14px;
			margin-top: 200px;
			text-align: center;
			.chektitle {
			    font-size: 18px;
			    color: #050001;
			    font-weight: 400;
			}
			.next-step {
			    width: 280px;
			    height: 48px;
			    background: #f13f48;
			    color: #fff;
			    line-height: 48px;
			    border-radius: 5px;
			    margin-top: 85px;
			    margin-bottom: 70px;
			    display: inline-block;
			    cursor: pointer;
				em {
				    opacity: .6;
				}
				.opacity{
					opacity: 1;
				}
			}
		}
	}
	/deep/.el-radio-group{
		display: flex;
		justify-content: center;
		flex-direction: column;
		.el-radio{
			margin: 30px auto 0;
			height: 30px;
			line-height: 30px;
		}
		.el-radio__label{		
			font-size: 16px;
		}
		.el-radio__inner{
			width: 20px;
			height: 20px;
			border: none; 
			border-radius: 0;
			background: url(../../assets/img/login/ico.png) 0 -181px no-repeat;
		}
		.el-radio__input{
			&.is-checked{
				.el-radio__inner{
					background-position: -28px -181px;
					&::after{
						width: 0px;
						height: 0px;
					}
				}
				&+.el-radio__label{
					color: #f13f48;
				}
			}
		} 
	}
</style>
